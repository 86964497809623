@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "PingFang SC", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #ffffff;
  box-sizing: border-box;
  /* letter-spacing: 1.3px; */
  overflow-x: hidden !important;
}

html,
body {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
